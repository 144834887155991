import React, { useEffect, useState } from 'react'
import image from "./images/B.png"

const Footer = () => {


    const [link,setLinks]=useState([]);
    async function fetchLink(){
  
      const datas=await fetch("https://api.bsquareparivar.in/all?data=masterdata");
      
      const response=await datas.json();
      console.log(response);
      setLinks(response);
  }
  
  
  useEffect(()=>{
    fetchLink();
  },[])

  return (

<div>
    <div className="body p-10" style={{backgroundColor:"#2582ff "}}>

        <div className='container'>
        <div className="row">

            <div className="col-md-3">
                <div className="footer-logo-container" style={{cursor:"pointer"}}>
                    <img src={image}
                        alt="" />
                </div>
                <p className="footer-address-container-content" style={{marginTop:"8px",fontSize:"14px"}}>B-Square Academy offers classroom programs for NEET, 11th , 12th Foundation & 12th Passed & 6 To 10 Pre-Foundation </p>
                <p className="social-links-container-title" style={{marginTop:"20px"}}>Follow us on</p>
                <div className="social-links-container-images">
                    <a href="https://www.instagram.com/bsquareacademy/" target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                    </svg>
                    </a>
                    <a href="https://www.twitter.com/bsquareacademy/" target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                    </a>
                    <a href="https://www.facebook.com/bsquaremertacity/" target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                    </svg>
                    </a>
                    <a href="https://wa.me/919875286421" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                            <path d="M13.601 2.326A7.931 7.931 0 0 0 8 0a7.978 7.978 0 0 0-7.982 8c0 1.404.372 2.774 1.083 3.987L0 16l4.14-1.081A7.933 7.933 0 0 0 8 16c4.418 0 8-3.582 8-8a7.97 7.97 0 0 0-2.399-5.674zM8 14.318a6.297 6.297 0 0 1-3.307-.902l-.237-.141-2.457.642.656-2.398-.154-.247A6.267 6.267 0 0 1 1.703 8a6.297 6.297 0 0 1 6.298-6.318A6.297 6.297 0 0 1 14.318 8a6.297 6.297 0 0 1-6.318 6.318z"/>
                            <path d="M11.124 9.436c-.168-.084-1.003-.496-1.158-.554-.154-.056-.265-.084-.376.084-.112.168-.431.554-.528.668-.097.112-.194.126-.361.042-.168-.084-.709-.26-1.35-.83-.498-.437-.834-.977-.932-1.145-.097-.168-.011-.26.073-.344.075-.075.168-.194.253-.292.084-.097.112-.168.168-.28.056-.112.028-.209-.014-.292-.042-.084-.376-.901-.515-1.233-.14-.332-.279-.286-.376-.292-.097-.006-.209-.007-.321-.007-.112 0-.292.042-.445.209-.153.167-.583.57-.583 1.39s.597 1.611.68 1.724c.084.112 1.175 1.796 2.851 2.521.399.172.71.275.952.353.399.127.763.109 1.05.066.32-.05 1.003-.41 1.144-.807.14-.397.14-.737.098-.807-.042-.07-.154-.112-.322-.196z"/>
                        </svg>
                    </a>

                    <a href="https://www.youtube.com/channel/UC_ELqkFPe0WytSbJoehVW5w" target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                    </svg>
                    </a>
                </div>
            </div>
            <div className="col-md-3">
                <p className="gci-links-title">Quick Links</p>
                <div className="row" style={{marginTop:"10px"}}>
                    <a className="hover-branch col-md-12" href="/">Home</a>
                    <a className="hover-branch" href="/about">About B-Square</a>
                    <a className="hover-branch" href="/our-programs">Our Programs</a>
                    <a className="hover-branch" href="/results">Results</a>
                    <a className="hover-branch" href="https://blog.bsquareparivar.in">Blogs</a>
                    <a className="hover-branch" href="https://bsquarebest.in">Scholarship-Test</a>
                    <a className="hover-branch" href="">Download Brochure</a>
                </div>
            </div>
            <div className="col-md-3">
                <div className="row gap-4">
                    <p className="gci-links-title">Join Us</p>

                        <button type="button" className="sign-in-btn w-4/6" >

                                            {
                                            link.map((links)=>{
                                                if(links.keyname==="APPLYONLINE"){
                                                    return(
                                                        <a href={links.value} className='col-md-12' target='_blank'>
                                                        Apply For Admission
                                                        </a>
                                                    )
                                                }
                                            })
                                        }

                        </button>


                        <button type="button" className="sign-in-btn w-4/6 col-md-12"  >
                        <a href='/career' target='_blank' className='col-md-12'>
                        Career
                        </a>
                        </button>


                        <button type="button" className="sign-in-btn w-4/6 col-md-12" >
                        <a href='https://bsquarebest.in' target='_blank' >
                        Scholarship Test
                        </a>
                        </button>


                   
                </div>
            </div>
            
                <div className="col-md-3">
                    <p className="footer-address-container-title">Address</p>
                    <p className="footer-address-container-content">
                    Sanskar Tower,Civil Line,Merta City,<br/>Nagaur(Raj.) - 341510
                    </p>
                    <div style={{display:"flex",marginTop:"10px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                    </svg>
                        <a href="mailto:info@concepthmh.com">bsquareneet@gmail.com </a>
                    </div>
                    <div style={{display:"flex",marginTop:"8px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                    </svg>
                        <a href="tel:+9187645-15151">
                        8764515151</a>
                    </div>
                    <p className="social-links-container-title" style={{marginTop:"20px"}}>Map</p>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28528.229978749994!2d74.042237!3d26.647562!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396a481aae386ce7%3A0xdafeb4d50892d36a!2sBsquare%20academy%2CMerta%20City!5e0!3m2!1sen!2sin!4v1738777311834!5m2!1sen!2sin"
                        width="240" height="120" style={{marginTop:"10px"}} loading="lazy" className='cards'
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            
        </div>
        {/* <div style={{height:"0.1px",width:"100%",backgroundColor:"white",marginTop:"20px",marginBottom:"20px"}} className='mb-20'></div> */}
        {/* <div>
        <text className="gci-links-title-city">Currently, We Are In 4 Cities</text>
        </div> */}

        {/* <div className='container'>
            <div className='col-md-3 mb-4'>
            <p className="" style={{textAlign:"left" }} >
                                <a className="hover-branch text-[1.26rem]" href="/">Hanumangarh(Branch Office)</a>
                            </p>
                            <p className="footer-address-container-content-city">
                                <a className="hover-branch" href="/">Police Thana Road,<br/>Opposite Vyapar Sangh Dharamshala,<br/>Hanumangarh Junction(Raj.)</a>
                            </p>
                            
                                <a href="tel: +91-9875017955">
                                    +91-9875017955
                                    <span> ,  </span>
                                    +91-7230955595
                                </a>
                            
            </div>
            <div className='col-md-3  mb-4'>

                <p className="" style={{textAlign:"left"}}>
                    <a className="hover-branch text-[1.26rem]" href="/">Bikaner (Head Office)</a>
                </p>
                <p className="footer-address-container-content-city">
                    <a className="hover-branch" href="/">Polytechnic College Road,<br/>Patel Nagar, Bikaner(Raj.)</a>
                </p>
                <div style={{display:"flex",marginTop:""}} className='flex flex-col'>
                    <a href>
                        0151-2240651
                    </a>
                    <a>
                        +91-9928111865
                    </a>
                </div>

            </div>
<div className='col-md-3  mb-4'>

                <p className="" style={{textAlign:"left"}}>
                    <a className="hover-branch text-[1.26rem]" href="/">Nagaur  (Branch Office)</a>
                </p>
                <p className="footer-address-container-content-city">
                    <a className="hover-branch" href="/">Behind Circuit House,<br/>Near Basni Pulia,Nagaur(Raj.)</a>
                </p>
                <div style={{display:"flex",marginTop:""}} className='flex flex-col'>
                    <a href>
                        +91-7300080901
                    </a>
                    <a>
                        +91-7300080902
                    </a>
                </div>
</div>
<div className='col-md-3  mb-4'>

                <p className="" style={{textAlign:"left"}}>
                    <a className="hover-branch text-[1.26rem]" href="/">Suratgarh(Branch Office)</a>
                </p>
                <p className="footer-address-container-content-city">
                    <a className="hover-branch" href="/">1st Floor Burger Co. Near UCO Bank,Opp. Mahraja Cinema, Suratgarh(Raj.)</a>
                </p>
                <div style={{display:"flex",marginTop:""}} className='flex flex-col'>
                    <a href>
                        +91-6367991913 <span> , </span> 9352148181
                    </a>
                    <a className=''>
                        9145968181
                    </a>
            </div>

</div>

        </div> */}
    </div>
</div>
</div>

    
  )
}

export default Footer













