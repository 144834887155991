import React, { useEffect, useState } from 'react'
import image from "./images/B.png"
 
const Header = () => {

    const [state,setState]=useState("false")
    const [flag,setFlag]=useState(false)

    const [link,setLinks]=useState([]);
    async function fetchLink(){
  
      const datas=await fetch("https://api.bsquareparivar.in/all?data=masterdata");
      
      const response=await datas.json();
      console.log(response);
      setLinks(response);
  }
  
  useEffect(()=>{
    fetchLink();
  },[])
  return (

    <div>
    
    <div className="flex justify-center items-center">
        <div className="header-container w-[95%] rounded-xl h-28 flex justify-around items-center">
            <div className="gurukripa-logo">
                <a href='/'>
                <img style={{cursor:"pointer"}}
                src={image}
                alt="concept logo"/>
                </a>

            </div>

            <div className={`nav-links ${flag===true?"mobile-menu-open":""} `} >

                <a
                
                    className={`header__mob-side-view-active-link header__mob-side-view-Link-container header__tab-view-Link-container ${state ==="Home" ? "font-bold" :""} hover:font-bold transition-all duration-200`}   
                    href="/"
                    >
                        <button onClick={()=>{
                        setState("Home")
                            }}>
                            <img src="" alt="" className="header__mob-side-view-icon" />Home
                        </button>

                </a>
                <a target="" className={`header__mob-side-view-Link-container header__tab-view-Link-container ${state ==="About" ? "font-bold" :""}  hover:font-bold transition-all duration-200`}
                    href="/about">
                        <button onClick={()=>{
                        setState("About")
                            }}>
                            <img src="" alt="" className="header__mob-side-view-icon" />About
                        </button>
                </a>
                <a target="" className= {`header__mob-side-view-Link-container header__tab-view-Link-container ${state ==="Our" ? "font-bold" :""} hover:font-bold transition-all duration-200`}
                    href="/our-programs"

                    >
                        <button onClick={()=>{
                        setState("Our")
                            }}>
                            <img src="" alt="" className="header__mob-side-view-icon" />Our Programs
                        </button>
                </a>
                <a target="" className={`header__mob-side-view-Link-container header__tab-view-Link-container ${state ==="Results" ? "font-bold" :"" } hover:font-bold transition-all duration-200`}
                    href="/results"
                    >
                        <button onClick={()=>{
                        setState("Results")
                            }}>
                            <img src="" alt="" className="header__mob-side-view-icon" />Results
                        </button>
                </a>
                <a target="_blank" className= {`header__mob-side-view-Link-container header__tab-view-Link-container ${state ==="Blogs" ? "font-bold" :""} hover:font-bold transition-all duration-200`}
                    href="https://blog.bsquareparivar.in"
                    >
                        <button onClick={()=>{
                        setState("Blogs")
                            }}>
                            <img src="" alt="" className="header__mob-side-view-icon" />Blogs
                        </button>
                </a>
                <a target="" className= {`header__mob-side-view-Link-container header__tab-view-Link-container ${state ==="Career" ? "font-bold" :""} hover:font-bold transition-all duration-200`}
                    href="/career"
                    >
                        <button onClick={()=>{
                        setState("Career")
                            }}>
                            <img src="" alt="" className="header__mob-side-view-icon" />Career
                        </button>
                </a>
                <a href='https://bsquarebest.in' className= {`header__mob-side-view-Link-container header__tab-view-Link-container ${state ==="Career" ? "font-bold" :""} hover:font-bold transition-all duration-200`} target='_blank'>
                    <div className="scholarship-btn">Scholarship Test</div>
                </a>

                <a  className= {`header__mob-side-view-Link-container header__tab-view-Link-container ${state ==="LatestUpdates" ? "font-bold" :""} hover:font-bold transition-all duration-200`}
                    href="/latest-updates"
                    >
                        <button onClick={()=>{
                        setState("LatestUpdates")
                            }}>
                            <img src="" alt="" className="header__mob-side-view-icon" />Latest Updates
                        </button>
                </a>

                <a target="" className= {`header__mob-side-view-Link-container header__tab-view-Link-container ${state ==="Download" ? "font-bold" :""} hover:font-bold transition-all duration-200`}
                    href="/downloads"
                    >
                        <button onClick={()=>{
                        setState("Download")
                            }}>
                            <img src="" alt="" className="header__mob-side-view-icon" />Downloads
                        </button>
                </a>
                
                                        {
                                            link.map((links)=>{
                                                if(links.keyname==="APPLYONLINE"){
                                                    return(
                                                        <a className=" inline-block" href={links.value} target='_blank'>
                                                        <button type="button" className="sign-in-btn">Apply Online</button>
                                                    </a>
                                                    )
                                                }
                                            })
                                        }

            </div>
            <button onClick={()=>{
                setFlag(!flag);
            }}>
            <div className="mobile-menu-icon">
                <div className="bar "></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            </button>

        </div>
    </div> 


</div>
  )
}

export default Header