import React from 'react'
import ReactDOM from "react-dom/client";
import UpdatePage from '../components/UpdatePage/UpdatePage';
const LatestUpdates = () => {
    const updates = ReactDOM.createRoot(document.getElementById("updates"));
    updates.render(
        <UpdatePage/>
    )
}

export default LatestUpdates