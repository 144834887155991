import React, { useEffect, useState } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import ButtonHelp from '../common/ButtonHelp'
import Button from '../Home/Button'
import ImageRenderer from '../common/ImageRenderer'
import image from "../common/images/medal image.png"
const Programs = () => {


    const [images,setImages]=useState([]);
    async function fetchImages(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=otherimages");

        const response=await datas.json();
        console.log(response);
        setImages(response);
    
    }
    console.log(images)

    useEffect(()=>{
        fetchImages();
    },[])

  return (



<div id="__next">

        <Header/>
            <div className="container-body">
                <div className="">
                    <div style={{padding:"0 2.5% 0 2.5%"}}>
                        <div>
                        <div className="banner-image-container">
                            <ImageRenderer images={images.filter((img)=>img.itype==='our-programs')}/>
                            </div>
                            {/* <div className="framework-for-intelligence">
                                <img src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/our-programs/our-cousers-intelligent-framework-2.svg" alt="framework-for-intelligence"/>
                            </div> */}
                        </div>
                    </div>
                    <div className="our-programs-container">
                        <h1 className="our-programs-container-title">
                            Our Programs <br/>Best NEET Coaching in Rajasthan
                        </h1>
                        <div className="our-programs-layout-4-container mt-10">
                            <div className="our-programs-layout-4">
                                <div className="our-programs-layout-4-image-container">
                                  <ImageRenderer images={images.filter((img)=>img.itype==='prefoundation')}/>
                                </div>
                                <div className="our-programs-layout-4__right">
                                    <div className="our-prgrams-id-container">
                                        <p className="our-prgrams-id__2-mobile our-prgrams-id">1</p>
                                        <p>Pre foundation Course</p>
                                    </div>
                                    <div className="margin-correction-layout-4">
                                        <p className="our-prgrams-id__content">B-Square Pre Foundation Division offers specialized coaching for students in grades 8 to 10. Our program extends beyond the standard school curriculum and board examinations, preparing students for various scholarship exams, including national and international Olympiads.</p>
                                    </div>
                                </div>
                                <div className="our-programs-layout-4-image-container__mobile-version">
                                <ImageRenderer images={images.filter((img)=>img.itype==='prefoundation')}/>
                                </div>
                            </div>
                            <p className="our-prgrams-id__batch margin-correction__layout-3">Batches</p>
                            <div className="our-prgrams-id__batch-sign-up-card-container margin-correction__layout-3">
                                <div className="our-prgrams-id__batch-sign-up-card">
                                    <div className="our-prgrams-id__batch-sign-up-card__logo-bg">
                                        <img src={image} className='bg-' alt=""/>
                                    </div>
                                    <div className="our-prgrams-id__batch-sign-up-card__badge">
                                        <img src="https://bsquarebest.in/uploads/finance-help-svg.png" alt=""/>
                                        <p>Scholarship available </p>
                                    </div>
                                    <section className="our-prgrams-id__batch-sign-up-card__title_content">
                                        <p>
                                        VI Class

                                            <br/>
                                        </p>
                                        <p>	For VI Class Students</p>
                                        <ButtonHelp id={1}/>
                                    </section>
                                </div>
                                <div className="our-prgrams-id__batch-sign-up-card">
                                    <div className="our-prgrams-id__batch-sign-up-card__logo-bg">
                                        <img src={image} className='bg-' alt=""/>
                                    </div>
                                    <div className="our-prgrams-id__batch-sign-up-card__badge">
                                        <img src="https://bsquarebest.in/uploads/finance-help-svg.png" alt=""/>
                                        <p>Scholarship available </p>
                                    </div>
                                    <section className="our-prgrams-id__batch-sign-up-card__title_content">
                                        <p>
                                        VII Class

                                            <br/>
                                        </p>
                                        <p>	For VII Class Students</p>
                                        <ButtonHelp id={1}/>
                                    </section>
                                </div>
                                <div className="our-prgrams-id__batch-sign-up-card">
                                    <div className="our-prgrams-id__batch-sign-up-card__logo-bg">
                                        <img src={image} className='bg-' alt=""/>
                                    </div>
                                    <div className="our-prgrams-id__batch-sign-up-card__badge">
                                        <img src="https://bsquarebest.in/uploads/finance-help-svg.png" alt=""/>
                                        <p>Scholarship available </p>
                                    </div>
                                    <section className="our-prgrams-id__batch-sign-up-card__title_content">
                                        <p>
                                        VIII Class

                                            <br/>
                                        </p>
                                        <p>	For VIII Class Students</p>
                                        <ButtonHelp id={1}/>
                                    </section>
                                </div>
                                <div className="our-prgrams-id__batch-sign-up-card">
                                    <div className="our-prgrams-id__batch-sign-up-card__logo-bg">
                                        <img src={image} alt=""/>
                                    </div>
                                    <div className="our-prgrams-id__batch-sign-up-card__badge">
                                        <img src="https://bsquarebest.in/uploads/finance-help-svg.png" alt=""/>
                                        <p>Scholarship available </p>
                                    </div>
                                    <section className="our-prgrams-id__batch-sign-up-card__title_content">
                                        <p>
                                        IX Class

                                            <br/>

                                        </p>
                                        <p>	For IX Class Students</p>
                                        <ButtonHelp id={1}/>
                                    </section>
                                </div>
                                <div className="our-prgrams-id__batch-sign-up-card bg-green-800">
                                    <div className="our-prgrams-id__batch-sign-up-card__logo-bg">
                                        <img src={image} alt=""/>
                                    </div>
                                    <div className="our-prgrams-id__batch-sign-up-card__badge">
                                        <img src="https://bsquarebest.in/uploads/finance-help-svg.png" alt=""/>
                                        <p>Scholarship available </p>
                                    </div>
                                    <section className="our-prgrams-id__batch-sign-up-card__title_content">
                                        <p>
                                        X Class

                                            <br/>

                                        </p>
                                        <p>	For X Class Students</p>
                                        <ButtonHelp id={1}/>
                                    </section>
                                </div>
                            </div>
                            
                            <div className="our-programs-layout__left-hr">
                                <hr/>
                            </div>
                        </div>
                        <div className="our-programs-layout-4-container mt-10">
                            <div className="our-programs-layout-4">
                                <div className="our-programs-layout-4-image-container">
                                <ImageRenderer images={images.filter((img)=>img.itype==='neet')}/>
                                </div>
                                <div className="our-programs-layout-4__right">
                                    <div className="our-prgrams-id-container">
                                        <p className="our-prgrams-id__2-mobile our-prgrams-id">2</p>
                                        <p>NEET</p>
                                    </div>
                                    <div className="margin-correction-layout-4">
                                        <p className="our-prgrams-id__content">At B-Square, we offer comprehensive coaching, a supportive environment, and excellent facilities for students preparing for the Medical Entrance Exam. Our experienced team of trainers specializes in NEET UG exam preparation, providing guidance and motivation to help students maximize their potential and achieve their goals</p>
                                    </div>
                                </div>
                                <div className="our-programs-layout-4-image-container__mobile-version">
                                <ImageRenderer images={images.filter((img)=>img.itype==='neet')}/>
                                </div>
                            </div>
                            <p className="our-prgrams-id__batch margin-correction__layout-3">Batches</p>
                            <div className="our-prgrams-id__batch-sign-up-card-container margin-correction__layout-3">
                                <div className="our-prgrams-id__batch-sign-up-card">
                                    <div className="our-prgrams-id__batch-sign-up-card__logo-bg">
                                        <img src={image} alt=""/>
                                    </div>
                                    <div className="our-prgrams-id__batch-sign-up-card__badge">
                                        <img src="https://bsquarebest.in/uploads/finance-help-svg.png" alt=""/>
                                        <p>Scholarship available </p>
                                    </div>
                                    <section className="our-prgrams-id__batch-sign-up-card__title_content">
                                        <p>
                                        BRIGHT Batch
                                            <br/>
                                        </p>
                                        <p>	For X to XI Moving Students</p>
                                        <ButtonHelp id={3}/>
                                    </section>
                                </div>
                                <div className="our-prgrams-id__batch-sign-up-card">
                                    <div className="our-prgrams-id__batch-sign-up-card__logo-bg">
                                        <img src={image} alt=""/>
                                    </div>
                                    <div className="our-prgrams-id__batch-sign-up-card__badge">
                                        <img src="https://bsquarebest.in/uploads/finance-help-svg.png" alt=""/>
                                        <p>Scholarship available </p>
                                    </div>
                                    <section className="our-prgrams-id__batch-sign-up-card__title_content">
                                        <p>
                                        BRILLIANT Batch

                                            <br/>
                              
                                        </p>
                                        <p>For XI to XII Moving Studying Students</p>
                                        <ButtonHelp id={3}/>
                                    </section>
                                </div>
                                <div className="our-prgrams-id__batch-sign-up-card">
                                    <div className="our-prgrams-id__batch-sign-up-card__logo-bg">
                                        <img src={image} alt=""/>
                                    </div>
                                    <div className="our-prgrams-id__batch-sign-up-card__badge">
                                        <img src="https://bsquarebest.in/uploads/finance-help-svg.png" alt=""/>
                                        <p>Scholarship available </p>
                                    </div>
                                    <section className="our-prgrams-id__batch-sign-up-card__title_content">
                                        <p>
                                        EXCELLENT Batch

                                            <br/>
                           
                                        </p>
                                        <p>For XII Passed Students.</p>
                                        <ButtonHelp id={3}/>
                                    </section>
                                </div>
                            </div>
                            
                            <div className="our-programs-layout__left-hr">
                                <hr/>
                            </div>
                        </div>
                        
                    </div>
                    <div className="academic-path-form-container ass">
                        <div className="academic-path-form-container__left-content">
                            <p className="academic-path-form-container__title ">Uncertain about which academic path to choose?</p>
                            <p className="academic-path-form-container__subtitle">Leave your contact details, and our dedicated team will reach out to you for a personalized consultation. Let &#x27;s shape your journey together!</p>
                        </div>
                        <button className='color hover:scale-125 scale-150 rounded-lg px-4 py-2 transition-all duration-200 mt-4 -translate-x-2 text-white'>
                            <a href='https://account.vedmarg.com/guruprerna-1990/request-a-call-back'>
                                Enquire now
                            </a>
                        </button>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>

  )
}

export default Programs



        


