import React, { useEffect } from 'react'
import ReactDOM from "react-dom/client";
import Slider from "../components/Home/Slider";
import Test from '../components/Home/Test';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Cards from '../components/Home/Cards';
import Button from '../components/Home/Button';
import Render from '../components/Home/Render';
import ExpertMentors from '../components/Home/ExpertMentors';
import Features from '../components/Home/Features';
import Future from '../components/Home/Future';

const Home = () => {

const roots = ReactDOM.createRoot(document.getElementById("roots"));
const dabba = ReactDOM.createRoot(document.getElementById("imageContainer"));
const root=ReactDOM.createRoot(document.getElementById("root"));
const header=ReactDOM.createRoot(document.getElementById("header"));
const footer=ReactDOM.createRoot(document.getElementById("footer"));
const cards=ReactDOM.createRoot(document.getElementById("cards"))
const hide=ReactDOM.createRoot(document.getElementById("hide"));
const features=ReactDOM.createRoot(document.getElementById("features"));
const future=ReactDOM.createRoot(document.getElementById("future"));

header.render(

        <Header/>

)
root.render(

        <Test/>

)

roots.render(

      <Slider/>

  );

footer.render(

        <Footer/>

)
cards.render(

    <Cards/>

)

dabba.render(
  <Render/>
)
hide.render(
  <ExpertMentors/>
)
features.render(
  <Features/>
)
future.render(
  <Future/>
)

}

export default Home