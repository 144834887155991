import React, { useEffect, useState } from 'react'

const Render = () => {

    const [data,setData]=useState([]);
    async function fetchData(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=blockswithimage");
        
        const response=await datas.json();
        console.log(response);
        setData(response);
    
    }

    useEffect(()=>{
        fetchData();
    },[]);
  return (
    <div>
        
        <ul id="banners">
            {
                data.map((datas)=>{
                    if(datas.itype==="whyconcept"){
                        return(
                            <li className="banner" id="card1">
                                <div className={` ${datas.id%2===0?"bg-whyConcept1":"bg-whyConcept2"} card-body`} >
                                    <div>
                                        <h2 className="scrollable-title">{datas.title}</h2>
                                        <p>{datas.desc}</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="300" height="250"
                                            viewBox="0 0 300 250" fill="none" className="Ellipse-effect">
                                            <defs>
                                                <filter id="filter0_f_903_2751" x="-100" y="-100" width="400"
                                                    height="400" filterUnits="userSpaceOnUse"
                                                    color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                                        result="shape"></feBlend>
                                                    <feGaussianBlur stdDeviation="50"
                                                        result="effect1_foregroundBlur_903_2751"></feGaussianBlur>
                                                </filter>
                                            </defs>
                                        </svg>
                                    </div>
                                    <img src={`https://bsquarebest.in/uploads/`+datas.imageurl}
                                        alt="" />
                                </div>
                            </li>
                        )
                    }
                })
            }

                        </ul>


    </div>
  )
}

export default Render