import React from 'react'
import ReactDOM from "react-dom/client";
import CareerData from '../components/Career/CareerData';

const Career = () => {

    const careers = ReactDOM.createRoot(document.getElementById("careers"));
    careers.render(

            <CareerData/>
    
    )

}

export default Career