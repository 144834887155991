import React from 'react'

const ButtonHelp = ({id}) => {

    if(id===1){
        return (
            <div>
                <button>
                    <a href={"know-more/Pre-Foundation"}>
                        Know More
                    </a>
        
                </button>
        
            </div>
          )
    }
    if(id===2){
        return (
            <div>
                <button>
                    <a href={"know-more/IIT-JEE"}>
                    Know More
                    </a>
        
                </button>
        
            </div>
          )
    }
    if(id===3){
        return (
            <div>
                <button>
                    <a href={"know-more/NEET"}>
                    Know More
                    </a>
        
                </button>
        
            </div>
          )
    }
    if(id===4){
        return (
            <div>
                <button>
                    <a href={"know-more/RBSE,CBSE-BOARD"}>
                    Know More
                    </a>
        
                </button>
        
            </div>
          )
    }
    if(id===5){
        return (
            <div>
                <button>
                    <a href={"know-more/Evening-Batches"}>
                    Know More
                    </a>
                </button>
        
            </div>
          )
    }

}

export default ButtonHelp