import React, { useEffect, useState } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import location from "../common/images/Location.png"
import Button from '../Home/Button'
import Sliders from '../Home/Sliders'
import app from "../common/images/app.png"
import ImageRenderer from '../common/ImageRenderer'
const Info = () => {

    const [images,setImages]=useState([]);
    
    const [data,setData]=useState([]);

    const [link,setLinks]=useState([]);

    const [location,setLocation]=useState([]);

    const [formData,setFormData]=useState({
        location:"Hanumangarh"
    });
    


    async function fetchData(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=blockswithimage");
        
        const response=await datas.json();
        console.log(response);
        setData(response);

    }

        
    async function fetchLocation(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=address");
        
        const response=await datas.json();
        console.log(response);
        setLocation(response);
    
    }
    async function fetchLink(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=masterdata");
        
        const response=await datas.json();
        console.log(response);
        setLinks(response);


    }

        
    const clickHandler = (event) => {
        const {name,value,type,checked}=event.target;
        setFormData(prevFormData=>{
            return{
                ...prevFormData,
                [name]:type==="checkbox"?checked:value
            }
        })
        console.log(formData);
 };
    async function fetchImages(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=otherimages");

        const response=await datas.json();
        console.log(response);
        setImages(response);
    
    }
    console.log(images)

    useEffect(()=>{
        fetchImages();
        fetchData();
        fetchLocation();
        fetchLink();
    },[])

  return (
    <div>
        <div>
        <div id="__next">
            <Header/>

            <div className="container-body">
                <div>
                    <div>
                        <div className="banner-image-container">
                        <ImageRenderer images={images.filter((img)=>img.itype==='about')}/>    
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1>
                                    Cracking NEET is not so tough with B-Square
                                </h1>
                                <p>The successful strategy for NEET
                                    selection implemented at B-Square</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4">
                                <div className="create-success-container__right__flex">
                                    <div className="create-success-container__right__ellipse">1</div>
                                    <div className="create-success-container__right__flex__content">
                                        <p>Basic</p>
                                        <ul>
                                            <li>Learning basic fundamentals and concepts</li>
                                            <li>Learning direct application of basic concepts</li>
                                            <li>Through Regular Classroom Sessions</li>
                                            <li>Result oriented Study Material</li>
                                            <li>Daily practice problems (DPP)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="create-success-container__right__flex">
                                    <div className="create-success-container__right__ellipse">2</div>
                                    <div className="create-success-container__right__flex__content">
                                        <p>Advanced</p>
                                        <ul>
                                            <li>Development of problem solving skills</li>
                                            <li>Development of analytical skills for logic based challenging problems</li>
                                            <li>Through exercise sheets</li>
                                            <li>Doubt removal classNamees</li>
                                            <li>Extra classNamees if required</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="create-success-container__right__flex">
                                    <div className="create-success-container__right__ellipse">3</div>
                                    <div className="create-success-container__right__flex__content">
                                        <p>Temperament</p>
                                        <ul>
                                            <li>Knowing strong and weak areas</li>
                                            <li>Enhancement of speed, accuracy and test temperament</li>
                                            <li>Through periodic tests</li>
                                            <li>Test analysis sessions</li>
                                            <li>Feedback on performance</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4">
                                <div className="create-success-container__right__flex">
                                    <div className="create-success-container__right__ellipse">4</div>
                                    <div className="create-success-container__right__flex__content">
                                        <p>Shaping</p>
                                        <ul>
                                            <li>Learning time reducing techniques for solving problems</li>
                                            <li>Improve scoring ability &amp;shaping in rankers</li>
                                            <li>Through special attention to bright students</li>
                                            <li>Formation of special batches</li>
                                            <li>Extra care for weak students</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="create-success-container__right__flex">
                                    <div className="create-success-container__right__ellipse">5</div>
                                    <div className="create-success-container__right__flex__content">
                                        <p>Pre-final</p>
                                        <ul>
                                            <li>Revision of complete syllabus</li>
                                            <li>Reducing mistakes</li>
                                            <li>Through revision test series (DTS, GOTS)</li>
                                            <li>Special problem solving classNamees</li>
                                            <li>Special psychological classNamees</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="create-success-container__right__flex">
                                    <div className="create-success-container__right__ellipse">6</div>
                                    <div className="create-success-container__right__flex__content">
                                        <p>Final</p>
                                        <ul>
                                            <li>Acquiring sound examination temperament</li>
                                            <li>Development of confidence for appearing in entrance exams</li>
                                            <li>Through full syllabus test (MTS)</li>
                                            <li>Rehearsal for final exam</li>
                                            <li>Special yoga and pranayam classNamees</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-4">
                                <div className="create-success-container__right__flex">
                                    <div className="create-success-container__right__ellipse">7</div>
                                    <div className="create-success-container__right__flex__content">
                                        <p>Final Revision</p>
                                        <ul>
                                            <li>Revise your className room notes</li>
                                            <li>Revise skipped and wrong problem of your module.</li>
                                            <li>Re solve the skipped and wrong question of DTS and paper you attempt year
                                                long</li>
                                            <li>Revise notes and concept with help of short notes</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="create-success-container__right__flex">
                                    <div className="create-success-container__right__ellipse">8</div>
                                    <div className="create-success-container__right__flex__content">
                                        <p>Final Exam</p>
                                        <ul>
                                            <li>Take sufficient asleep before last night</li>
                                            <li>Reach at exam centre at least 2 hr before</li>
                                            <li>Be hydrate, don’t take any type of stress</li>
                                            <li>Remain dominant on paper during exam</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="create-success-container__right__flex">
                                    <div className="create-success-container__right__ellipse">9</div>
                                    <div className="create-success-container__right__flex__content">
                                        <p>Enjoy Your Success</p>
                                        <ul>
                                            <li>Enjoy your success with your family, friends and teachers</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="the-hearbeat__bottom-hr">
                        <hr />
                    </div>
                    <div className="seminars-and-awards-comtainer">
                        <h2 className="our-journey-h2">
                            <span style={{color:"#f27d15"}}></span>
                            Inspiration, Seminars, and Celebrations for
                        </h2>
                        <section className="seminars-and-awards-title">
                            <p>
                                <span>NEET Students</span>
                                at B-Square
                            </p>
                            <p>Empowering Students, Building Confidence, and Sparking Inspiration</p>
                        </section>

                {
                data.map((datas)=>{
                    if(datas.itype==="about"){
                        return(
                                                    
                        <section className="seminars-and-awards-comtainer-content">
                        <div className="seminars-and-awards-comtainer-content__left">
                            <p>
                                {datas.title}
                            </p>
                            <p>{datas.desc}</p>
                        </div>
                        <div className="card-body">
                            <img src={`https://bsquarebest.in/uploads/`+datas.imageurl}
                                alt="" />
                        </div>
                    </section>
                        )
                    }
                })
            }

                    </div>
                    <div className="our-campus-component-tablet-view">
                        <div>
                            <div className="our-campus-gallery-component">

                                <div className="our-campus-gallery-tags">
                                    <p className="our-campus-gallery-tags-1">Empowering Dreams, Shaping Futures</p>
                                    <p className="our-campus-gallery-tags-2">Your Path to Success Begins Here with B-Square</p>
                                </div>

                                {
                                            link.map((links)=>{
                                                if(links.keyname==="About"){
                                                    return(

                                                        <div className="our-campus-gallery-card">
                                                        <img src="https://bsquarebest.in/uploads/gallery-card-svg-1.svg"
                                                            alt="" />
                                                        <p className="our-campus-gallery-card-p">{links.value}</p>
                                                        <p className="our-campus-gallery-card-p-mobile">{links.value}</p>
                                                        <img src="https://bsquarebest.in/uploads/gallery-card-svg-2.png"
                                                            alt="" />
                                                    </div>
                                                    )
                                                }
                                            })
                                        }

                            </div>

                            {/* <div className="campus-facilities-container">
                                <div id="toast" className="toast">&quot;The form has been submitted.&quot;</div>
                                <div className="card-container">
                                    <div className="ellipse">
                                        <img src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/OurCampus/campus-facilities-form/Ellipse 38.svg"
                                            alt="" />
                                    </div>
                                    <div className="star">
                                        <img src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/OurCampus/campus-facilities-form/star.svg"
                                            alt="" />
                                    </div>
                                    <div className="arrow">
                                        <img src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/OurCampus/campus-facilities-form/arrow.svg"
                                            alt="" />
                                    </div>
                                    <p className="title">Discover the comfort and comprehensive facilities at our campus.</p>
                                    <div className="form-container text-center">
                                        <div className="row-3">
                                            <button type="button" style="cursor:pointer">Enquire Now</button>
                                        </div>

                                    </div>
                                </div>
                            </div>  */}
                            <div className="discover-our-campus-container">
                                <p className="discover-our-campus-title">
                                    Discover Our<span className="discover-our-campus-title-highlight">Campus</span>
                                    <span>
                                        <img src="https://bsquarebest.in/uploads/discover-campus-title-img.svg"
                                            alt="" />
                                    </span>
                                </p>
                                <div className="city-image-container">
                                    <img src="https://bsquarebest.in/uploads/city-img.png"
                                        alt="" />
                                </div>
                                <p className="discover-our-campus-container-content">
                                    <span> Merta City,</span>
                                    located in the Nagaur district of Rajasthan, is a historically significant town known for its association with Meerabai, the famous saint-poet. Founded by Rao Duda, it was once a prominent Rajput stronghold. The city features historical sites like the Meera Bai Temple and Aurangzeb Mosque, reflecting its diverse past. Merta played a key role in battles between Rajputs and Mughals. Today, it is known for agriculture and trade, much like other towns in the region. It is located 60 km from Nagaur, 105 km from Jodhpur, and 190 km from Jaipur.
                                </p>
                                <div className="reach-us-container">
                                    <img src="https://bsquarebest.in/uploads/reach-us-svg.svg"
                                        alt="" />
                                    <p className="reach-us-text">Reach Us</p>
                                    <div className="full-map-container">

                                        <div className="map-form-container">
                                            <p>Getting to our Campus</p>
                                            <div className="starting-point-input">
                                                <img src="https://bsquarebest.in/uploads/destination-svg.png"
                                                    alt="" />
                                                <select style={{minWidth:"200px"}} id="select" onChange={clickHandler} name="location"  value={formData.location || ""}>
                                                    <option value="Hanumangarh">Merta City</option>
                                                </select>
                                            </div>
                                            <div style={{marginTop:"20px"}}>
                                                <text
                                                    style={{marginTop:"40px",marginLeft:"10px",fontSize:"1.2rem",fontWeight:"500"}}>Address</text>
                                            </div>
                                            <div style={{marginTop:"1px",marginLeft:"10px"}}>


                                            <div className='font-medium text-lg'>
                                                Sanskar Tower,Civil Line,Merta City,
                                                <br/> Nagaur(Raj.) - 341510
                                            </div>





                                            </div>
                                        </div>
                                        <div className="map-container" style={{width:""}}>
                                                         <iframe
                                                         className='responsive-iframe'
                                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28528.229978749994!2d74.042237!3d26.647562!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396a481aae386ce7%3A0xdafeb4d50892d36a!2sBsquare%20academy%2CMerta%20City!5e0!3m2!1sen!2sin!4v1738777311834!5m2!1sen!2sin"
                                                        width="100%" height="100%" style={{border:"0"}} allowfullscreen=""
                                                        loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="app-container">
                        <div className="app-container__left">
                            <p className="app__container-left-first-p">
                                Explore Admission Details on <span>B-Square App</span>
                            </p>
                            <p className="app__container-left-second-p">B-Square App, Your bright path to
                                all-encompassing admission information and seamless query resolution!</p>
                            <div className="app-container__left__btns">
                                <a href="https://play.google.com/store/apps/details?id=co.thanos.rmjjs" target="_blanck">
                                    <button>
                                        <img src="https://bsquarebest.in/uploads/play-store.png"
                                            alt="" />
                                    
                                        Get The App
                                    </button>
                                </a>
                                <a href="https://apps.apple.com/us/app/classplus/id1324522260" target="_blanck">
                                    <button>
                                        <img src="https://bsquarebest.in/uploads/app-store.png"
                                            alt=""/>Get The App
                                    </button>
                                </a>

                            </div>
                        </div>
                        <div className="app-container__right">
                            <img src={app} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Info