import React, { useEffect, useState } from 'react'

const Sliders = () => {
  const [imageData,setImageData]=useState([]);
  async function fetchData(){
    const datas=await fetch("https://api.bsquareparivar.in/all?data=resultimages");
    const response=await datas.json();
    console.log(response);
    setImageData(response);
}


useEffect(()=>{
  fetchData();
},[]);
    
  return (

    <div>

    <div className="alumni-slider  overflow-hidden">
    <div className="marquee-scroll flex gap-x-8">



         <div className='flex gap-10'>
         {
                                      imageData.map((data)=>{
                                        if(data.itype==="1"){
                                          return(
                                            <div className="alumni-image-wrapper">
                                              <img src={`https://bsquarebest.in/uploads/`+data.image} className='alumni-image first-image'/>
                                           </div>
                                       
                                        )
                                        }

                                    })
                                }
        </div> 

        </div>
    </div>
</div>
  )
}

export default Sliders