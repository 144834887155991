import React, { useEffect, useState } from 'react'

const ApiData = ({id}) => {

    const [html,setHtml]=useState([]);
    const [html1,setHtml1]=useState([]);
    const [html2,setHtml2]=useState([]);
    const [html3,setHtml3]=useState([]);
    const [html4,setHtml4]=useState([]);
    async function fetchHtml(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=courses&value=prefoundation");
        
        const response=await datas.json();
        console.log(response);
        setHtml(response);
    
    }
    
    async function fetchHtml1(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=courses&value=iitjee");
        
        const response=await datas.json();
        console.log(response);
        setHtml1(response);
    
    }
    
    async function fetchHtml2(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=courses&value=neet");
        
        const response=await datas.json();
        console.log(response);
        setHtml2(response);
    
    }
    
    async function fetchHtml3(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=courses&value=board");
        
        const response=await datas.json();
        console.log(response);
        setHtml3(response);
    
    }
    
    async function fetchHtml4(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=courses&value=evening");
        
        const response=await datas.json();
        console.log(response);
        setHtml4(response);
    
    }
    useEffect(()=>{
        fetchHtml();
        fetchHtml1();
        fetchHtml2();
        fetchHtml3();
        fetchHtml4();
    },[])

    console.log(id)
    if(id===1){
        return (
            <div className='flex justify-center mt-10 '>
                {
                    html.map((htmls)=>{
                        return(
                            <div>
                            <img src={`https://bsquarebest.in/uploads/${htmls.Banner}`} className='mb-10'/>
                            <div key={htmls.id} dangerouslySetInnerHTML={{ __html: htmls.content }} />
                            </div>
                        )
                    })
                }
            </div>
          )
    }
    
    if(id===2){
        return (
            <div className='flex justify-center mt-10 '>
                {
                    html1.map((htmls)=>{
                        return(
                            <div>
                            <img src={`https://bsquarebest.in/uploads/${htmls.Banner}`} className='mb-10'/>
                            <div key={htmls.id} dangerouslySetInnerHTML={{ __html: htmls.content }} />
                            </div>
                        )
                    })
                }
            </div>
          )
    }
    
    if(id===3){
        return (
            <div className='flex justify-center mt-10 '>
                {
                    html2.map((htmls)=>{
                        return(

                            <div>
                            <img src={`https://bsquarebest.in/uploads/${htmls.Banner}`} className='mb-10'/>
                            <div key={htmls.id} dangerouslySetInnerHTML={{ __html: htmls.content }} />
                            </div>
                        )
                    })
                }
            </div>
          )
    }
    
    if(id===4){
        return (
            <div className='flex justify-center mt-10 '>
                {
                    html3.map((htmls)=>{
                        return(

                            <div>
                            <img src={`https://bsquarebest.in/uploads/${htmls.Banner}`} className='mb-10'/>
                            <div key={htmls.id} dangerouslySetInnerHTML={{ __html: htmls.content }} />
                            </div>
                        )
                    })
                }
            </div>
          )
    }
    
    if(id===5){
        return (
            <div className='flex justify-center mt-10 '>
                {
                    html4.map((htmls)=>{
                        return(

                            <div>
                            <img src={`https://bsquarebest.in/uploads/${htmls.Banner}`} className='mb-10'/>
                            <div key={htmls.id} dangerouslySetInnerHTML={{ __html: htmls.content }} />
                            </div>
                        )
                    })
                }
            </div>
          )
    }
}

export default ApiData