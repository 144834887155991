import React, { useEffect, useState } from 'react'

const Button = () => {

  const [link,setLinks]=useState([]);
  async function fetchLink(){

    const datas=await fetch("https://api.bsquareparivar.in/all?data=masterdata");
    
    const response=await datas.json();
    console.log(response);
    setLinks(response);
}

useEffect(()=>{
  fetchLink();
},[])
  return (
    <div>

                    <div className="">
                        <div className="">
                            <div className="flex justify-center items-center w-[90%]">
                                <button type="button" className="register-btn hover:scale-95 hover:bg-blue-500 transition-all duration-200" tabindex="0">

                                        {
                                            link.map((links)=>{
                                                if(links.keyname==="ENQUIRENOW"){
                                                    return(
                                                      <a href={links.value} target='_blank'>
                                                      Enquire Now
                                                      </a>
                                                    )
                                                }
                                            })
                                        }
                                  

                                    </button>
                            </div>
                        </div>
                    </div>
    </div>
  )
}

export default Button