import React, { useEffect, useState } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import ImageRenderer from '../common/ImageRenderer';
import DataTable from 'react-data-table-component';

const DownloadComponent = () => {

  const [images,setImages]=useState([]);
  const [download,setDownload]=useState([]);
  const [id,setId]=useState("");
  const [subId,setSubId]=useState("")
  const [mainCategory,setMainCategory]=useState([]);
  const [subCategory,setSubCategory]=useState([]);
  const [flag1,setFlag1]=useState(false);
  const [flag2,setFlag2]=useState(false);


  const columns = [
    {
      name: "File Name",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
    },
    
    {
      name: "Date & Time",
      selector: (row) => row.timedate,
      sortable: true,
    },
    {
      name: "Category Name",
      selector: (row) => row.catname,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          style={{
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            padding: "5px 10px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
        <a
          href={`https://bsquarebest.in/uploads/${row.file}`}
          target="_blank"
          rel="noopener noreferrer"
        >
         View
        </a>
        
        </button>
      ),
    },
  ];

  async function fetchImages(){

      const datas=await fetch("https://api.bsquareparivar.in/all?data=otherimages");

      const response=await datas.json();
      console.log(response);
      setImages(response);
  
  }
  console.log(images)

  
  async function fetchMainCategory(){

    const datas=await fetch("https://api.bsquareparivar.in/all?data=downloadscategory&value=cat");

    const response=await datas.json();
    console.log(response);
    setMainCategory(response);

}

async function fetchSubCategory(){

  const datas=await fetch(`https://api.bsquareparivar.in/all?data=downloadscategory&value=subcat&id=${id}`);

  const response=await datas.json();
  console.log(response);
  setSubCategory(response);

}

async function fetchDownloads(){

  const datas=await fetch(`https://api.bsquareparivar.in/all?data=downloads&catid=${id}&subcatid=${subId}`);

  const response=await datas.json();
  console.log(response);
  setDownload(response);

}

  useEffect(()=>{
      fetchImages();
      fetchMainCategory();
      fetchSubCategory();
      fetchDownloads();
  },[id,subId])


  return (

    <div>

        <Header/>
        <div className=''>
        <div className="banner-image-container">
           <ImageRenderer images={images.filter((img)=>img.itype==='downloads')}/>
        </div>
        </div>



        <div className={`grid-wrapper mx-auto w-10/12 mt-20`}>

            {
              mainCategory.map((category)=>{
                if(flag1===false){
                  return(
                    <div className='category-card'>
                    <button className='category-name' onClick={()=>{
                        setFlag1(true);
                        {setId(category.CategoryId)}
                    }}>
                            {category.Name}
  
                    </button>
                    </div>
                  )
                }
              })
            }


        </div>

        <div className={`grid-wrapper mx-auto w-10/12 mt-20`}>

          {
            subCategory.map((category)=>{
              if(flag2===false){
                return(
                  <div className='category-card'>
                  <button className='category-name' onClick={()=>{
                      setFlag2(true);
                      {setSubId(category.CategoryId)}
                  }}>
                      {category.Name}
                  </button>
                  </div>
                )
              }
            })
          }


  </div>


  
  <div className={`mx-auto`}>

      {
        download.map((category)=>{
          if(flag2===true){
            return(

              <div style={{ margin: "20px" }} className='ml-10'>
              <DataTable
                  columns={columns}
                  data={download}
                  pagination
                  highlightOnHover
                  striped
                />
              </div>
            )
          }
        })
      }


</div>



        <Footer/>
  
    </div>
    
  )
}

export default DownloadComponent