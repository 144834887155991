import React, {useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import ApiData from './ApiData';
import Header from '../common/Header';
import Footer from '../common/Footer';


const KnowMorePage = () => {

    const [token,setToken]=useState("")
    let id=0
    const location=useLocation();
    const handleOnSubmit=()=>{
        setToken(location.pathname.split('/').at(2));
        console.log(token)
    }
    if(token==="Pre-Foundation"){
        id=1;
    }
    if(token==="IIT-JEE"){
        id=2;
    }
    if(token==="NEET"){
        id=3;
    }
    if(token==="RBSE,CBSE-BOARD"){
        id=4;
    }
    if(token==="Evening-Batches"){
        id=5;
    }
    useEffect(handleOnSubmit,[]);
  return (
    <div className='mt-40'>
        <Header/>
        <h1 style={{padding:0}}>{token}</h1>
        <ApiData id={id}/>
        <Footer/>
    </div>
  )
}

export default KnowMorePage