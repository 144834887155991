import React from 'react'

const ErrorPage = () => {
  return (
    <div className='flex items-center justify-center text-4xl h-[100vh] text-black'>
        Error 404
    </div>
  )
}

export default ErrorPage