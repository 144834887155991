import React from 'react'
import ReactDOM from "react-dom/client";
import DownloadComponent from '../components/Download/DownloadComponent';

const Download = () => {

    const download = ReactDOM.createRoot(document.getElementById("download"));
    download.render(

            <DownloadComponent/>

    )

}

export default Download