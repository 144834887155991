import React, { useEffect, useState } from 'react'
import careerImage from "../common/images/Career image.webp"
import Header from '../common/Header'
import Footer from '../common/Footer'
import 'animate.css';
import ImageRenderer from '../common/ImageRenderer';

const CareerData = () => {

    const [images,setImages]=useState([]);
    const [link,setLinks]=useState([]);
    async function fetchImages(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=otherimages");
        
        const response=await datas.json();
        console.log(response);
        setImages(response);
    
    }
    async function fetchLink(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=masterdata");
        
        const response=await datas.json();
        console.log(response);
        setLinks(response);


    }

    useEffect(()=>{
        fetchImages();
        fetchLink();
    },[])

  return (
    <div>
        <Header/>

        <div className="banner-image-container">
        <ImageRenderer images={images.filter((img)=>img.itype==='career')}/>
        </div>

        <div className='flex flex-col gap-10'>
            <h1 className='text-4xl text-green-900 text-center'>Non-Teaching</h1>
            <div className='flex items-center justify-center'>
            <p className=' text-lg w-[70%] text-center animation'>
            The non-teaching department in a coaching center plays a crucial role in ensuring the smooth and efficient functioning of the institution. From administrative tasks, student counseling, and admissions to managing logistics, finance, and facilities, the non-teaching staff work behind the scenes to support the academic team. Their dedication ensures that operations run seamlessly, helping to create a conducive learning environment for students. By handling day-to-day responsibilities, they allow teachers and students to focus fully on the educational process, contributing significantly to the success of the coaching center.
            </p>
            </div>
        </div>

        <div className='flex items-center justify-center'>
                <button className=' text-center text-[1rem] px-6 py-2 mt-10 rounded-3xl font-bold color text-white 
                                    hover:scale-95 transition-all duration-200 w-auto'>

                                        {
                                            link.map((links)=>{
                                                if(links.keyname==="NONTEACHINGLINK"){
                                                    return(
                                                        <a href={links.value} target='_blank'>
                                                        Apply Now
                                                        </a>
                                                    )
                                                }
                                            })
                                        }
                                      
                </button>
        </div>

        <div className='flex flex-col gap-10'>
            <h1 className='text-4xl text-green-900 text-center'>Teaching</h1>
            <div className='flex items-center justify-center'>
            <p className=' text-lg w-[70%] text-center animation'>
            The teaching department in a coaching center is the cornerstone of its success, focusing on delivering quality education and guidance to students. Educators in this department are subject-matter experts who not only impart knowledge but also mentor and motivate students to achieve their academic goals. They design and execute lesson plans, conduct assessments, and provide personalized support to ensure that every student excels. Through their dedication, creativity, and commitment to excellence, the teaching department transforms learning into a dynamic and engaging experience, driving the overall success of the coaching center and its students.
            </p>
            </div>
        </div>

        <div className='flex items-center justify-center'>
                <button className=' text-center text-[1rem] px-6 py-2 mt-10 rounded-3xl font-bold color text-white
                                    hover:scale-95 transition-all duration-200 w-auto'>


                                        
{
                                            link.map((links)=>{
                                                if(links.keyname==="TEACHINGLINK"){
                                                    return(
                                                        <a href={links.value} target='_blank'>
                                                        Apply Now
                                                        </a>
                                                    )
                                                }
                                            })
                                        }

                </button>
        </div>


        <Footer/>

    </div>
  )
}

export default CareerData