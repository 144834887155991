import React from 'react'
import ReactDOM from "react-dom/client";
import Programs from '../components/Programs/Programs';

const OurPrograms = () => {
    const targetss = ReactDOM.createRoot(document.getElementById("targetss"));
    targetss.render(

            <Programs/>

    )
}

export default OurPrograms