import React from 'react'
import ReactDOM from "react-dom/client";
import Info from '../components/About/Info';

const About = () => {
    const target = ReactDOM.createRoot(document.getElementById("targets"));
    target.render(
      
            <Info/>
 
    )
}

export default About