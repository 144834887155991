import { Route, Routes } from "react-router";
import "./App.css";
import Test from "./components/Home/Test";
import About from "./pages/About";
import Home from "./pages/Home";
import OurPrograms from "./pages/OurPrograms";
import Results from "./pages/Results";
import Career from "./pages/Career";
import Download from "./pages/Download";
import KnowMore from "./pages/KnowMore";
import LatestUpdates from "./pages/LatestUpdates";
import ErrorPage from "./components/common/ErrorPage";
import Errors from "./pages/Errors";

function App() {
  return (
    <div>
          <Routes>
            <Route
            path="/about"
            element={
              <About/>
            
            }
            />
            <Route
            path="/"
            element={
              <Home/>
            }
            />
            <Route
            path="/our-programs"
            element={
              <OurPrograms/>
            }
            />
            <Route
            path="/results"
            element={
              <Results/>
            }

            />

            <Route
            path="/career"
            element={
              <Career/>
            }
            />
           <Route
            path="/downloads"
            element={
              <Download/>
            }
            />

          <Route
            path="/know-more/:id"
            element={
              <KnowMore/>
            }
            />

            
          <Route
            path="/latest-updates"
            element={
              <LatestUpdates/>
            }
            />

          <Route path="*"
          element={<Errors/>}/>
        </Routes>


    </div>



  );
}

export default App;
