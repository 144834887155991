import React from 'react'
import ReactDOM from "react-dom/client";
import ErrorPage from '../components/common/ErrorPage';
const Errors = () => {

    const error=ReactDOM.createRoot(document.getElementById("nothing"));
    error.render(
        <ErrorPage/>
    )
}

export default Errors