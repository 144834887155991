import React from 'react'
import ReactDOM from "react-dom/client";
import ResultCode from '../components/Result/ResultCode';


const Results = () => {

    const aim = ReactDOM.createRoot(document.getElementById("aims"));
    aim.render(

            <ResultCode/>

    )

}

export default Results





