import React, { useEffect, useState } from 'react'

const Future = () => {


    
    const [data,setData]=useState([]);
    async function fetchData(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=blockswithimage");
        
        const response=await datas.json();
        console.log(response);
        setData(response);
    
    }

    useEffect(()=>{
        fetchData();
    },[]);
  return (
    <div>
            <p className="component-title-future-sikar">Join the best NEET coaching in Rajasthan today</p>
            <div className="container-future-body">
            <div className='container-future-sikar'>
{
                data.map((datas)=>{
                    
                    if(datas.itype==="Jointhebest"){
                        return(

                                <div className={` ${datas.id%2===0?"bg-future1":"bg-future2"} item-future`}>
                                    <h2 style={{color:"#AA51F4"}} className="future-h2">
                                        <a href="#">{datas.title}</a>
                                    </h2>
                                    <p className="future-p">{datas.desc}</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="300" height="250" viewBox="0 0 300 250"
                                        fill="none" className="Ellipse-effect">
                                        <defs>
                                            <filter id="filter0_f_903_2751" x="-100" y="-100" width="400" height="400"
                                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                                    result="shape"></feBlend>
                                                <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_903_2751">
                                                </feGaussianBlur>
                                                
                                            </filter>
                                        </defs>
                                    </svg>

                        </div>
                        )
                    }
                })
            }
            </div>
            </div>



    </div>
  )
}

export default Future