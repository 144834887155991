import React, { useEffect, useState } from 'react';

const ImageRenderer = ({ images }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (images && images.length > 0) {
      setImageUrl(images[0].imageurl); // Set the first image URL
    }
  }, [images]); // Re-run effect if `images` changes

  if (!imageUrl) {
    return <p>No image available</p>; // Fallback if no images are found
  }
  console.log(imageUrl);

  return (
    <div cla>
      <img
        src={`https://bsquarebest.in/uploads/${imageUrl}`}
        alt="Dynamic Image"
      />
    </div>
  );
};

export default ImageRenderer;

