import React, { useEffect, useState } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Sliders from '../Home/Sliders'
import ImageRenderer from '../common/ImageRenderer'

const ResultCode = () => {
    const [imageData,setImageData]=useState([]);
    
    const [formData,setFormData]=useState({ExamType:"",Branch:"",Year:""});


    
    const [images,setImages]=useState([]);
    async function fetchImages(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=otherimages");

        const response=await datas.json();
        console.log(response);
        setImages(response);
    
    }
    console.log(images)

    useEffect(()=>{
        fetchImages();
    },[])



    async function fetchData(){
        const datas=await fetch("https://api.bsquareparivar.in/all?data=resultimages");
        const response=await datas.json();
        console.log(response);
        setImageData(response);
    }
    console.log(imageData);

    
    const clickHandler = (event) => {
             const {name,value,type,checked}=event.target;
        setFormData(prevFormData=>{
          return{
              ...prevFormData,
              [name]:type==="checkbox"?checked:value
          }
      })
      };

      useEffect(()=>{
        fetchData();
      },[]);
      {
        imageData.map((image)=>{
            console.log(image)
        })
      }
      function submitHandler(event){
        event.preventDefault();
       setFormData(
        {
            ExamType:"",
            Branch:"",
            Year:""
        }
       )
      }
      console.log(formData);
  return (

    <div id="__next">
    <Header/>
    <div className="container-body">
        <div className="">
            <div className="results-banner-container">
                <div className="results-banner-container-content">
                    <h1>From Aspirations to Achievements</h1>
                    <p>Extremely excellent results from extremely limited average students 🏆</p>
                    <svg className="results-banner-container-svg" width="813" height="700" viewBox="0 0 813 700" fill="none">
                        <path opacity="0.2" d="M375.377 -15L375.377 798M311.862 -15L311.862 798M248.346 -15L248.346 798M184.83 -15L184.83 798M121.315 -15L121.315 798M57.7992 -15L57.7992 798M0 74.557H813M0 138.073H813M0 201.588H813M0 265.104H813M0 328.62H813M0 392.135H813M0 455.651H813M0 519.166H813M0 582.682H813M0 646.198H813M0 709.713H813M0 773.229H813M438.893 -15L438.893 798M502.409 -15L502.409 798M565.924 -15L565.924 798M629.44 -15L629.44 798M692.955 -15V798M756.471 -15V798M0 11.0414H813" stroke="#297515" stroke-width="1.27031"></path>
                    </svg>
                </div>
                <div className="results-banner__tab-view-title-and-sub-title">
                    <p>From Aspirations to Achievements</p>
                    <p>Extremely excellent results from extremely limited average students 🏆</p>
                </div>
                <div className="results-banner-image-container">
                    <div className="slide-container">
                        <div dir="ltr" aria-roledescription="carousel">
                            <div className="react-slideshow-container">
                                <div className="react-slideshow-wrapper slide ">
                                    <div className="images-wrap horizontal" style={{transform:"translateX(-0px)"}}>
                                        <div data-index="-1" aria-roledescription="slide" aria-hidden="true">
                                            <div className="each-slide">
                                                <div className="slide-image-size">
                                                    <img className="slide-image-size" src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/home/Mobile%2BMain%2BSlider%2B03.webp" alt="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/home/Mobile%2BMain%2BSlider%2B03.webp"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-index="0" className="active" aria-roledescription="slide" aria-hidden="false">
                                            <div className="each-slide">
                                                <div className="slide-image-size">
                                                <ImageRenderer images={images.filter((img)=>img.itype==='results')}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-index="1" className="" aria-roledescription="slide" aria-hidden="true">
                                            <div className="each-slide">
                                                <div className="slide-image-size">
                                                    <img className="slide-image-size" src="/images copy/Banner/1.jpg" alt="/images copy/Banner/1.jpg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-index="2" className="" aria-roledescription="slide" aria-hidden="true">
                                            <div className="each-slide">
                                                <div className="slide-image-size">
                                                    <img className="slide-image-size" src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/home/Mobile%2BMain%2BSlider%2B03.webp" alt="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/home/Mobile%2BMain%2BSlider%2B03.webp"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-index="3" aria-roledescription="slide" aria-hidden="true">
                                            <div className="each-slide">
                                                <div className="slide-image-size">
                                                    <img className="slide-image-size" src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/home/Mobile%2BMain%2BSlider%2B01.webp" alt="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/home/Mobile%2BMain%2BSlider%2B01.webp"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide-container-mobile">
                        <div dir="ltr" aria-roledescription="carousel">
                            <div className="react-slideshow-container">
                                <div className="react-slideshow-wrapper slide ">
                                    <div className="images-wrap horizontal" style={{transform:"translateX(-0px)"}}>
                                        <div data-index="-1" aria-roledescription="slide" aria-hidden="true">
                                            <div className="each-slide">
                                                <div className="slide-image-size">
                                                    <img className="slide-image-size" src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/home/Mobile%2BMain%2BSlider%2B03.webp" alt="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/home/Mobile%2BMain%2BSlider%2B03.webp"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-index="0" className="active" aria-roledescription="slide" aria-hidden="false">
                                            <div className="each-slide">
                                                <div className="slide-image-size">
                                                <ImageRenderer images={images.filter((img)=>img.itype==='results')}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-index="1" className="" aria-roledescription="slide" aria-hidden="true">
                                            <div className="each-slide">
                                                <div className="slide-image-size">
                                                    <img className="slide-image-size" src="/images copy/Banner/1.jpg" alt="/images copy/Banner/1.jpg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-index="2" className="" aria-roledescription="slide" aria-hidden="true">
                                            <div className="each-slide">
                                                <div className="slide-image-size">
                                                    <img className="slide-image-size" src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/home/Mobile%2BMain%2BSlider%2B03.webp" alt="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/home/Mobile%2BMain%2BSlider%2B03.webp"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-index="3" aria-roledescription="slide" aria-hidden="true">
                                            <div className="each-slide">
                                                <div className="slide-image-size">
                                                    <img className="slide-image-size" src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/home/Mobile%2BMain%2BSlider%2B01.webp" alt="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/home/Mobile%2BMain%2BSlider%2B01.webp"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="results-banner-container-content__mobile">
                    <p>Celebrating Success on a Global Stage</p>
                    <p className="results-banner__tab-view-title-and-sub-title__subtitle">Unveiling Our Achievers to the World! 🏆</p>
                    <svg className="results-banner-container-svg" xmlns="http://www.w3.org/2000/svg" width="813" height="700" viewBox="0 0 813 700" fill="none">
                        <path opacity="0.2" d="M375.377 -15L375.377 798M311.862 -15L311.862 798M248.346 -15L248.346 798M184.83 -15L184.83 798M121.315 -15L121.315 798M57.7992 -15L57.7992 798M0 74.557H813M0 138.073H813M0 201.588H813M0 265.104H813M0 328.62H813M0 392.135H813M0 455.651H813M0 519.166H813M0 582.682H813M0 646.198H813M0 709.713H813M0 773.229H813M438.893 -15L438.893 798M502.409 -15L502.409 798M565.924 -15L565.924 798M629.44 -15L629.44 798M692.955 -15V798M756.471 -15V798M0 11.0414H813" stroke="#297515" stroke-width="1.27031"></path>
                    </svg>
                </div>
            </div>
            <div className="results-page-alumni-display-slider">
                <div>
                    <div className="results">
                        <h2 className="results-para">
                            Explore the Achievements of Our Students in NEET Exams<span className="results-para-highlight"></span>
                        </h2>
                    </div>
                    <Sliders/>
                </div>
            </div>
            <div className="filter-search-results-layout">
                <div className="filter-search-results-layout__filter">
                    <p className="result-filter__first_heading">Best Performers</p>
                    <div className="result-filter">
                        <div className="result-filter-title-div">
                            <p className="result-title-style">FILTER</p>

                        {
                        (() => {
                            if (formData.Branch!==""||formData.ExamType!==""||formData.Year!=="") { // Replace '1' with your condition
                            return (
                                <button onClick={submitHandler}>
                                <p className="clear-filter-button">Clear filters</p>
                                </button>
                            );
                            }
                            return null; // If the condition is false, render nothing
                        })()
                        }
                       
                      

                            <div className="result-filter__tablet-view-dropdown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <mask id="mask0_45_25" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
                                        <rect x="0.147705" y="0.189209" width="24" height="24" fill="#D9D9D9"></rect>
                                    </mask>
                                    <g mask="url(#mask0_45_25)">
                                        <path d="M12.1477 15.5642L6.14771 9.56418L7.54771 8.16418L12.1477 12.7642L16.7477 8.16418L18.1477 9.56418L12.1477 15.5642Z" fill="#1C1B1F"></path>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    
                        <form>

               
                        <div className="flex flex-col justify-center px-10 gap-2">
                            <p className="result-title-style">Branches</p>
                            <label for="exam_jee-mains">
                                <input type="radio" name="Branch" id="exam_10th" value="Merta" checked={formData.Branch === 'Merta'} onChange={clickHandler}/>
                                Merta
                            </label>
                        </div>
                        <div className="flex flex-col justify-center px-10 gap-2">
                            <p className="result-title-style">EXAMS</p>
                            <label htmlFor="exam_jee-mains">
                                <input
                                type="radio"
                                name="ExamType"
                                id="exam_NEET"
                                value="neet"
                                checked={formData.ExamType === "neet"}
                                onChange={clickHandler}
                                />
                                NEET
                            </label>
                            <label htmlFor="exam_jee-mains">
                                <input
                                type="radio"
                                name="ExamType"
                                id="exam_Pre Foundation"
                                value="prefoundation"
                                checked={formData.ExamType === "prefoundation"}
                                onChange={clickHandler}
                                />
                                Pre Foundation
                            </label>

                        </div>

                        </form>


                        {/* Rank COde
                        <div className="result-filter-year-dropdown-container">
                            <p className="result-title-style">YEAR</p>
                            <select name="year" id="year">
                                <option value="" className="dropdown-placeholder" selected="">Select The Year</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                            <p className="result-rank-style">RANK</p>
                            <select name="rankRangeSelect" id="rankRangeSelect">
                                <option value="0,0" className="dropdown-placeholder">Select Rank Range</option>
                                <option value="0,100">0-100</option>
                                <option value="101,200">101-200</option>
                                <option value="201,300">201-300</option>
                                <option value="301,400">301-400</option>
                                <option value="401,500">401-500</option>
                                <option value="501,600">501-600</option>
                                <option value="601,700">601-700</option>
                                <option value="701,800">701-800</option>
                                <option value="801,1000">801-1000</option>
                                <option value="1001,2000">1001-2000</option>
                                <option value="2001,3000">2001-3000</option>
                            </select>
                        </div> */}


                        {/* <div className="result-filter-bottom-image">
                            <img src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/results/Group 87.png" alt=""/>
                        </div> */}
                    </div>
                </div>
                <div className="filter-search-results-layout__search-bar">
                    <div>
                        <div className="best-performers-1">
                            <p>Top Performers: Results of NEET Exam</p>
                        </div>
                        <div className="best-performer-card">
                            

                            
                             {
                                imageData.map((image)=>{
                                    if (
                                        (formData.Branch === "" || formData.Branch === image.branch) &&
                                        (formData.ExamType === "" || formData.ExamType === image.exam)
                                      ){
                                        return(
                                            <div>
                                                <img src={`https://bsquarebest.in/uploads/`+image.image} className='alumni-image first-image'/>
                                            </div>
                                        )
                                    }
                                    return null;  
                                })
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="results__academic-query-form">
                <div className="academic-path-form-container">
                    <div className="academic-path-form-container__left-content">
                        <p className="academic-path-form-container__title ">Not sure which academic path to be choose ?</p>
                        <p className="academic-path-form-container__subtitle">Leave your contact details, and our dedicated team will reach out to you for a personalized consultation. Let &#x27;s shape your journey together!</p>
                    </div>
                    <div className="academic-path-form-container__form">
                        <div className="contact-form-parent-wrapper">
                            <div className="hero-image-wrapper__tablet-view ">
                                <img src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/contact-form/cropped-hero-image.png" alt=""/>
                            </div>
                            <div className=" contact-form-wrapper-home">
                                <div className="contact-form">
                                    <div className="flex justify-center items-center w-[90%]">
                                        <button type="button" className="register-btn" tabindex="0">Enquire Now</button>
                                    </div>
                                </div>
                            </div>
                            <div className="hero-image-wrapper">
                                <img src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/contact-form/cropped-hero-image.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<Footer/>
</div>
  )
}

export default ResultCode



