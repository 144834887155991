import React, { useEffect, useState } from 'react'

const Features = () => {



    const [data,setData]=useState([]);
    async function fetchData(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=blockswithimage");
        
        const response=await datas.json();
        console.log(response);
        setData(response);
    
    }

    useEffect(()=>{
        fetchData();
    },[]);
  return (

    <div className="container-facilities-sikar">



        {
                data.map((datas)=>{
                    
                    if(datas.itype==="feature"){
                        return(
                            <div className={` ${datas.id%2===0?"bg-feature1":"bg-feature2"} item-facilities`} >
                            <h2 style={{color:"#AA51F4"}} className="facilities-h2">
                                <a href="#">{datas.title}</a>
                            </h2>
                            <p className="facilities-p">{datas.desc}</p>
                        </div>
                        )
                    }
                })
            }

</div>
  )
}

export default Features