import React, { useEffect, useState } from 'react'
import { Carousel, IconButton } from "@material-tailwind/react";
import ImageRenderer from '../common/ImageRenderer';
const Slider = () => {
    
    const [data,setData]=useState([]);

    async function fetchImages(){
        const datas=await fetch("https://api.bsquareparivar.in/all?data=slider");
        const response=await datas.json();
        console.log(response);
        setData(response);
    }
    useEffect(()=>{
        fetchImages();
    },[])

    console.log(data);

  return (
    // <div  id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
    //     <div className='carousel-inner '>
                                   
    //                         {
    //                             data.map((info)=>{
                                        
    //                                     return(
    //                                     <div className="carousel-item active banner-image-containe" data-bs-interval="10000">
    //                                         <img src={`https://bsquarebest.in/uploads/`+info.imageurl} alt="..." className="h-full w-full object-cover slide-container" />
    //                                         <img src={`https://bsquarebest.in/uploads/`+info.imageurl} alt="..." className="h-full w-full object-cover slide-container-mobile"/>
    //                                     </div>
    //                                     )
    //                                 })
    //                             } 
    //     </div>
    // </div>
<div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
    <div className="banner-image-container carousel-inner mx-auto">
        {data.map((info, index) => {
            const isActive = index === 0 ? 'active' : ''; 
            return (
                <div className={`carousel-item ${isActive}`} data-bs-interval="10000" key={index}>
                    <img 
                        src={`https://bsquarebest.in/uploads/` + info.imageurl} 
                        alt="..."
                        className="h-full w-full object-cover"
                    />
                </div>
                
            );
        })}
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev" >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
    </button>

    {/* Next Arrow */}
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
    </button> 
    </div>
</div>



)
}


export default Slider
                      
{/* 
                            <div className="carousel-item active">
                                <img src="https://tvaklaserclinic.gumlet.io/assets/img/treatments/tvak/1.png?w=1500"
                                    className="d-block w-100" alt="..."/>
                            </div> */}
             





