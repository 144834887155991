import React from 'react'
import Button from './Button'
const Cards = () => {


  return (
    <div className='w-11/12 mx-auto'>
        


        <div className="our-courses-wrapper">
             
                    <div className="grid-wrapper" style={{alignContent:"justifyBetween"}}>
                    
         
                        <a href='/our-programs'  className='cours'>
                        <div className="cards course-card" style={{background:"#E0D6FD", width:"[55%]"}}>
                            <div className="course-title-subtitle-wrapper">
                                <p className="course-title" style={{color:"#AA51F4"}}>Pre-Foundation</p>
                                <p className="course-subtitle"></p>
                                <h3 className="course-subtitle">For class 8th to 10th</h3>
                            </div>
                            <div className="icon-wrapper">
                                <img src="https://bsquarebest.in/uploads/Engineering-Divison.png"
                                    alt="icon" />
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="300" height="250" viewBox="0 0 300 250"
                                fill="none" className="Ellipse-effect">
                                <defs>
                                    <filter id="filter0_f_903_2751" x="-100" y="-100" width="400" height="400"
                                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                            result="shape">
                                        </feBlend>
                                        <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_903_2751">
                                        </feGaussianBlur>
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                        </a>
                        <a href='/our-programs'  className='course-cad'>
                        <div className="cards course-card" style={{background:"#98FFF5" , width:"[45%]"}}>
                            <div className="course-title-subtitle-wrapper">
                                <p className="course-title" style={{color:"#008B7E"}}>NEET (UG)</p>
                                <p className="course-subtitle"></p>
                                <h3 className="course-subtitle">For class 11th &amp;12th (Biology) <span>    </span> &12th passed</h3>
                            </div>
                            <div className="icon-wrapper">
                                <img src="https://bsquarebest.in/uploads/Pre-Foundation-Course.png"
                                    alt="icon" />
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="300" height="250" viewBox="0 0 300 250"
                                fill="none" className="Ellipse-effect">
                                <defs>
                                    <filter id="filter0_f_903_2751" x="-100" y="-100" width="400" height="400"
                                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                            result="shape">
                                        </feBlend>
                                        <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_903_2751">
                                        </feGaussianBlur>
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                        </a>
                        {/* <div className="cards" style={{background:"#E0D6FD"}}>
                            <div className="course-title-subtitle-wrapper">
                                <p className="course-title" style={{color:"#AA51F4"}}>IIT-JEE Target </p>
                                <p className="course-subtitle">(Main + Advanced)</p>
                                <h3 className="course-subtitle">For className 12th (Maths) Passed</h3>
                            </div>
                            <div className="icon-wrapper">
                                <img src="https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/gurukripa/images/our-courses/Medical-Divison.png"
                                    alt="icon" />
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="300" height="250" viewBox="0 0 300 250"
                                fill="none" className="Ellipse-effect-alternate">
                                <defs>
                                    <filter id="filter0_f_903_2751" x="-100" y="-100" width="400" height="400"
                                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                            result="shape">
                                        </feBlend>
                                        <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_903_2751">
                                        </feGaussianBlur>
                                    </filter>
                                </defs>
                            </svg>
                        </div> */}

                    
                    </div>
                    <div className='w-[40%] mx-auto mb-14 cards'>
                        <Button className="cards"  />
                    </div>
                </div>



    </div>
  )
}

export default Cards


