import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import { BrowserRouter } from "react-router";
import About from "./pages/About";

const root = ReactDOM.createRoot(document.getElementById("root"));

// const target=ReactDOM.createRoot(document.getElementById("target"));


// target.render(
//     <React.StrictMode>
//         <About/>
//     </React.StrictMode>
// )

root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App/>
    </BrowserRouter>
  </React.StrictMode>
);




