import React, { useEffect, useState } from 'react'

const ExpertMentors = () => {



    const [data,setData]=useState([]);
    async function fetchData(){

        const datas=await fetch("https://api.bsquareparivar.in/all?data=blockswithimage");
        
        const response=await datas.json();
        console.log(response);
        setData(response);
    
    }

    useEffect(()=>{
        fetchData();
    },[]);

  return (



    
        <div className="">
                        <p className="text-2xl font-semi-bold text-center pt-4 sm:text-xl sm:pl-2">Expert Mentors Ready to
                            Guide You.</p>



                        <div className="">

                        <div className="stat-card-container-parent-div__mobile stat-card-container-parent-div__desktop sm:flex justify-evenly mt-4 m-1">
                        {
                data.map((datas)=>{
                    
                    if(datas.itype==="expertmentors"){
                        return(

                                <div className="stat-card-container">
                                    <div className="">
                                        <p className="font-bold text-lg " style={{color:"#3889B1",fontSize:"1.4rem"}}>{datas.title}</p>
                                        <p style={{fontSize:"0.9rem",fontWeight:"bold"}}>{datas.desc}
                                            </p>
                                    </div>
                                    <div className="stat-card-container-img">
                                        <img src={`https://bsquarebest.in/uploads/${datas.imageurl}`} 
                                            alt="" />
                                    </div>
                                    <div className="-bottom-4 -right-4 absolute rounded-full w-12 h-12"
                                        style={{backgroundColor:"#adc7b7"}}></div>
                                </div>

 

                        )
                    }
                })
            }
                                       </div>
                                    <div className="stat-card-container-parent-div__mobile md:flex justify-evenly mt-4 m-1 mb-40 "></div>
                        </div>
            </div>
  )
}

export default ExpertMentors