import React from 'react'
import ReactDOM from "react-dom/client";
import KnowMorePage from '../components/KnowMorePage/KnowMorePage';
import { BrowserRouter } from 'react-router';

const KnowMore = () => {
    const know = ReactDOM.createRoot(document.getElementById("know"));
    know.render(
            <BrowserRouter>
            <KnowMorePage/>
            </BrowserRouter>
    )
}

export default KnowMore