import React, { useEffect, useState } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import ImageRenderer from '../common/ImageRenderer';

const UpdatePage = () => {

  const [images,setImages]=useState([]);
  const [updates,setUpdates]=useState([]);
  async function fetchImages(){

      const datas=await fetch("https://api.bsquareparivar.in/all?data=otherimages");

      const response=await datas.json();
      console.log(response);
      setImages(response);
  
  }


  async function latestUpdate(){

    const datas=await fetch("https://api.bsquareparivar.in/all?data=newsevents");

    const response=await datas.json();
    console.log(response);
    setUpdates(response);

}

  console.log(images)
  useEffect(()=>{
      fetchImages();
      latestUpdate();
  },[])

  return (
    
    <div className='con'>
        <Header/>
        <div className='container-body'>

       
             <div className=''>
             <div className="banner-image-container">
            <ImageRenderer images={images.filter((img)=>img.itype==='latest-updates')}/>
            </div>
              </div>
            {/* <div class="our-programs-container">
            {
              updates.map((update)=>{
                return(
                  
                  <div class="row w-3/12 mx-auto">
                  <div class="col-md-12 mt-5">
                  <div class="card">
                    <div class="date-box">
                      <span>{update.date}</span>
                    </div>
                    <div class="content">
                      <h3>{update.text}</h3>
                      <a href={update.link} class="details-link" target='_blank'>
                        <span>&#x2b07;</span> More Details
                      </a>
                    </div>
                  </div>
                  </div>
                  </div>
                )
              })
            }
            </div> */}
 
            <div className='grid-wrapper'>


            {
                updates.map((datas)=>{
                    
                        return(
                            <div className={` ${datas.id%2===0?"bg-feature1":"bg-feature2"} item-facilities w-6/12 mx-auto mt-10`} >
                            <h2 style={{color:"#AA51F4"}} className="facilities-h2">
                                <a href={datas.link} target='_blank'>{datas.text}</a>
                            </h2>
                            <p className="facilities-p details-link"> <span>&#x2b07;</span><a href={datas.link} target='_blank'>More details</a></p>
                        </div>
                        )
                    
                })
            }
            </div>
            </div>


        <Footer/>
    </div>
  )
}

export default UpdatePage